<template>
  <div class="forgot-password">
    <h5>{{ __('profile', 'Forgot password') }}</h5>
    <p class="description">{{ __('profile', "What's your registration email?") }}</p>
    <form action="" v-if="inputs" @submit.prevent="updateProfile">
      <div class="input-element"
           v-for="(item) in inputs"
           :key="item.name"
           :class="{error: findErrorField(errors, item.name)}">
        <div class="wrap">
          <input placeholder=" " :type="item.type" :id="item.name" v-model="item.value">
          <label :for="item.name">{{ item.label }}</label>
        </div>

        <div class="errors" v-if="errors">
          <div v-for="(i) in errors" :key="i.field">
            <p v-if="i.field === item.name">{{ i.message }}</p>
          </div>
        </div>
      </div>
      <div class="button-wrap">
        <button class="btn btn-primary" :class="{loading}" type="submit">
          <span>{{ __('global', 'Confirm') }}</span>
        </button>
      </div>
    </form>
  </div>
</template>

<script setup lang="ts">
import {ref} from "vue"
import {getAcceptSocialInputs} from "../Mixins/Forms"
import {findErrorField} from "../Mixins/Global"
import {__} from "../Mixins/i18n"
import {postPasswordReset} from "../Api/Registration"

const emit = defineEmits(['switchToSuccess'])

const inputs = ref<any>(getAcceptSocialInputs())
const errors = ref()
const loading = ref(false)

const updateProfile = async () => {
  loading.value = true
  errors.value = null
  const data: any = {}

  inputs.value.forEach((item: any) => {
    data[item.name] = item.value
  })

  await postPasswordReset(data).catch((error) => {
    errors.value = error.response.data
  })

  loading.value = false

  if (!errors.value) {
    emit('switchToSuccess', {
      title: 'Check your email',
      description: 'We have sent you password reset link'
    })

    inputs.value.forEach((item: any) => {
      item.value = null
    })
  }
}
</script>

<style lang="scss">
.forgot-password {
  h5 {
    text-align: center;
    margin-bottom: 1.6rem;
  }

  .description {
    text-align: center;
    margin-bottom: 2.4rem;
  }
}
</style>