import { DEFAULT_LANG } from '@/Mixins/Constants'
import { defineStore } from 'pinia'

export default defineStore('lang', {
  actions: {
    getLang() {
      const fromParam = this.router.currentRoute.value.params.lang
      if (fromParam) return fromParam as string
      const fromPath = window.location.pathname.replace(/^\/([^/]*).*$/, '$1')
      if (fromPath) return fromPath
      return DEFAULT_LANG
    },
  },
})
