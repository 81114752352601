<template>
  <div class="menu" :class="{ open: open }">
    <nav>
      <ul>
        <li v-if="width > 991">
          <div class="has-dropdown menu-item">
            <a href="#" @click.prevent>
              <span>{{ __('global', 'Products') }}</span>
              <SvgIcon name="chevron" />
            </a>
            <div v-if="dropdownOpenAllowed" class="dropdown">
              <div class="wrapper">
                <div class="container">
                  <div class="dropdown-top">
                    <h3>{{ __('header', 'Top Categories') }}</h3>
                    <router-link
                      v-if="globalStore.productsUrl"
                      :to="globalStore.productsUrl"
                      @click="closeDropdown"
                    >
                      <span>{{ __('global', 'All products') }}</span>
                      <SvgIcon name="arrow" />
                    </router-link>
                  </div>
                  <div
                    v-if="productsStore.productCategories"
                    class="categories"
                  >
                    <router-link
                      v-for="item in productsStore.productCategories"
                      :key="item.id"
                      :to="`${globalStore.productsUrl}/${item.slug}`"
                      @click="closeDropdown"
                    >
                      <div class="image">
                        <OptimizedImg
                          v-if="item.image"
                          alt=""
                          :size="{
                            mobile: { height: 260, width: 310 },
                            desktop: { height: 348, width: 236 },
                          }"
                          :src="item.image"
                        />
                      </div>
                      <p>
                        <span>{{ item.name }}</span>
                        <SvgIcon name="arrow" />
                      </p>
                    </router-link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </li>
        <li v-else>
          <div class="menu-item">
            <router-link
              v-if="globalStore.productsUrl"
              :to="globalStore.productsUrl"
            >
              {{ __('global', 'Products') }}
            </router-link>
          </div>
        </li>
        <li v-for="item in menu" :key="item.id">
          <div class="menu-item">
            <Link
              v-if="item.url && !item.children"
              :target-blank="item.open_new_tab"
              :url="item.url"
            >
              {{ item.title }}
            </Link>
            <div v-else class="popper-wrapper">
              <button class="popper-trigger" @click="toggleExpand($event)">
                {{ item.title }}
                <SvgIcon name="chevron" />
              </button>

              <span v-show="dropdownOpenAllowed" class="popper-content">
                <Link
                  v-for="child in item.children"
                  :key="child.id"
                  class="popper-content-item"
                  :target-blank="child.open_new_tab"
                  :url="child.url"
                  @click="closeDropdown"
                >
                  {{ child.title }}
                </Link>
              </span>
            </div>
          </div>
        </li>
      </ul>
    </nav>
    <div class="mobile-content">
      <ConditionalRouterLink
        :active="loginStore.userLoggedIn"
        class="profile"
        :to="toProfile"
        @disabled-click="
          () =>
            emit('open-sign-up', {
              redirectUrl: toProfile,
              isLogin: false,
            })
        "
      >
        <div>
          <SvgIcon name="profile" />
          <span>{{ __('global', 'My Profile') }}</span>
        </div>
        <SvgIcon class="chevron" name="chevron" />
      </ConditionalRouterLink>
      <ConditionalRouterLink
        v-if="cartStore.cart || !loginStore.userLoggedIn"
        :active="loginStore.userLoggedIn"
        class="cart"
        :to="toCart"
        @disabled-click="
          emit('open-sign-up', { redirectUrl: toCart, isLogin: false })
        "
      >
        <div>
          <SvgIcon name="cart" />
          <span>{{ __('global', 'My bag') }}</span>
          <p v-if="cartStore.cartItemQuantity" class="header-bubble">
            <span>{{ cartStore.cartItemQuantity }}</span>
          </p>
        </div>
        <SvgIcon class="chevron" name="chevron" />
      </ConditionalRouterLink>
      <div v-if="currency && country" class="preferences">
        <div class="preferences-text">
          <a class="selected" href="#" @click.prevent="togglePreferences">
            <span>{{ __('header', 'Preferences') }}</span>
            <span>{{ country }} | {{ currency }}</span>
          </a>
          <SvgIcon
            class="chevron"
            name="chevron"
            @click.prevent="togglePreferences"
          />
        </div>
        <PreferencesDropdown v-if="openPreferences" />
      </div>
      <div v-if="!loginStore.userLoggedIn" class="login">
        <button class="btn btn-secondary" @click="() => openSignUp(true)">
          {{ __('global', 'Sign in') }}
        </button>
        <button class="btn btn-primary" @click="() => openSignUp()">
          {{ __('global', 'Sign up') }}
        </button>
      </div>

      <div v-if="loginStore.userLoggedIn" class="sign-out">
        <button class="btn btn-primary" @click.prevent="logout">
          <span>{{ __('profile', 'Sign out') }}</span>
        </button>
      </div>

      <div v-if="socials" class="socials">
        <p>{{ __('header', 'Follow us on social media') }}</p>
        <SocialsIcons />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import PreferencesDropdown from './PreferencesDropdown.vue'
import SocialsIcons from '@/Partials/SocialsIcons.vue'
import SvgIcon from '@/Partials/SvgIcon.vue'
import Link from './Link.vue'
import { computed, ref } from 'vue'
import { __ } from '@/Mixins/i18n'
import { getHeaderMenu, getSiteTree } from '@/Api/Global'
import { useWindowSize } from '@vueuse/core'
import { useQuery } from 'vue-query'
import { useLoginStore } from '@/Store/useLoginStore'
import { useGlobalStore } from '@/Store/useGlobalStore'
import { useProductsStore } from '@/Store/useProductsStore'
import { SocialSetting } from '@/Types/Setting'
import { useCartStore } from '@/Store/useCartStore'
import { RouteLocationRaw, useRoute, useRouter } from 'vue-router'
import ConditionalRouterLink from './ConditionalRouterLink.vue'
import { withLang } from '@/routes'
import useLangStore from '@/Store/useLangStore'
import OptimizedImg from '@/Partials/ui/OptimizedImg.vue'
import useScreenBreakpoints from '@/Composables/UseScreenBreakpoints'

const props = defineProps<{
  open: boolean
  currency: string
  country: string
  openPreferences: boolean
}>()

const emit = defineEmits<{
  close: []
  'toggle-preferences': [value: boolean]
  'open-sign-up': [{ redirectUrl?: RouteLocationRaw; isLogin: boolean }]
}>()

const loginStore = useLoginStore()
const globalStore = useGlobalStore()
const productsStore = useProductsStore()
const cartStore = useCartStore()
const langStore = useLangStore()
const router = useRouter()
const { width } = useWindowSize()
const { isMobile } = useScreenBreakpoints()
const route = useRoute()

const socials = computed(
  () =>
    globalStore.getSettingByKey('socialsetting') as SocialSetting | undefined,
)

const { data: headerMenu } = useQuery('header-menu', () => getHeaderMenu())
const { data: siteTree } = useQuery('site-tree', () => getSiteTree())

const siteTreeMenuSections = computed(() => {
  return (
    siteTree.value
      ?.filter((section) => section.section_type === 'header-menu-section')
      ?.map(({ id, title, slug: url }) => ({
        id,
        title,
        url: route.name === 'home' ? `/${langStore.getLang()}/${url}` : url,
        children: siteTree.value
          .filter((child) => child.parent_section_id === id)
          .map(({ id, title, slug: url }) => ({
            id,
            title,
            open_new_tab: url.startsWith('http'),
            url:
              route.name !== 'home' || url.startsWith('http')
                ? url
                : `/${langStore.getLang()}/${url}`,
          })),
      })) || []
  )
})

const menu = computed(
  () => headerMenu.value?.concat(siteTreeMenuSections.value) || [],
)

const togglePreferences = () => {
  emit('toggle-preferences', !props.openPreferences)
}

const openSignUp = (login = false) => {
  emit('close')
  emit('open-sign-up', { isLogin: login })
}

const logout = async () => {
  await loginStore.logout()
  emit('close')
  await router.push(`/${langStore.getLang()}`)
}

const dropdownOpenAllowed = ref(true)

const closeDropdown = () => {
  if (isMobile.value) return

  dropdownOpenAllowed.value = false
  setTimeout(() => {
    dropdownOpenAllowed.value = true
  }, 10)
}

const toProfile = computed(() => withLang({ name: 'profile' }))

const toCart = computed(() => withLang({ name: 'cart' }))

const toggleExpand = (event: Event) => {
  const element = event.target as HTMLElement

  if (!isMobile.value) {
    element.classList.remove('expand')
  } else {
    element.classList.toggle('expand')
  }
}
</script>

<style lang="scss">
.menu {
  position: absolute;
  transform: translateX(101%);
  left: 0;
  top: 6.9rem;
  right: 0;
  bottom: 0;
  background: $white;
  height: calc(100vh - 6.9rem);
  overflow-y: auto;
  transition: 0.3s all;
  padding: 0 1.6rem;
  z-index: 11;
  @media (min-width: 1200px) {
    position: static;
    transform: none;
    overflow-y: inherit;
    height: auto;
    background: none;
    padding: 0;
  }

  &.open {
    @media (max-width: 1199px) {
      transform: translateX(0);
    }
  }

  nav {
    ul {
      display: flex;
      flex-direction: column;
      margin: 1.6rem 0 2.4rem 0;
      border-bottom: 1px solid $light-gray;
      padding-bottom: 1.8rem;
      @media (min-width: 1200px) {
        flex-direction: row;
        align-items: center;
        margin: 0;
        border-bottom: none;
        padding-bottom: 0;
      }
    }
  }

  li {
    margin: 0.7rem 0;
    @media (min-width: 1200px) {
      margin: 0 2.2rem 0 0;
    }
  }

  .menu-item {
    &.has-dropdown {
      &:hover,
      &:focus {
        .dropdown {
          display: block;
        }
      }
    }

    > a,
    .popper-trigger {
      padding: 1rem 0;
      color: $black;
      font-size: 1.4rem;
      width: 100%;
      white-space: nowrap;
      display: flex;
      justify-content: space-between;
      align-items: center;
      cursor: pointer;
      @media (min-width: 1200px) {
        padding: 2rem 1rem;
        width: auto;
        border-bottom: 2px solid transparent;
      }

      &:not(.popper-trigger):focus,
      &:not(.popper-trigger):hover {
        font-weight: 800;
        @media (min-width: 1200px) {
          border-bottom: 2px solid $dark-gray;
          font-weight: 400;
        }
      }

      svg {
        width: 8px;
        height: 8px;
        margin-left: 0.6rem;
      }
    }

    > a:not(.popper-trigger):after,
    .popper-content-item:after {
      content: '';
      background-image: url("data:image/svg+xml,%3Csvg fill='none' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 5 8'%3E%3Cpath d='m.933 7.2 3.2-3.2L.933.8' stroke='%23000' stroke-linecap='square'/%3E%3C/svg%3E");
      display: block;
      background-repeat: no-repeat;
      width: 9px;
      height: 9px;
      @media (min-width: 1200px) {
        display: none;
      }
    }

    .popper-wrapper {
      &:has(.expand) {
        .popper-content {
          opacity: 1;
          pointer-events: auto;
          max-height: 1000px;
          padding-top: 1.4rem;
        }
      }

      @include desktop-only {
        &:hover {
          .popper-content {
            opacity: 1;
            pointer-events: auto;
            top: calc(100% - 1rem);
          }
        }
      }

      .popper-trigger {
        position: relative;
        display: flex;
        flex-wrap: wrap;
        background: none;
        border: none;

        svg {
          width: 1.2rem;
          height: 1.2rem;
        }

        @include desktop-only {
          svg {
            width: 8px;
            height: 8px;
          }
        }
      }

      .popper-content {
        display: flex;
        flex-direction: column;
        opacity: 0;
        transition: 0.3s all;
        width: 100%;
        max-height: 0;

        @include desktop-only {
          position: absolute;
          top: calc(100% - 2rem);
          width: 20.8rem;
          border-radius: 1.4rem;
          padding: 0.8rem;
          border: 1px solid $light-gray;
          background-color: white;
          opacity: 0;
          pointer-events: none;
          max-height: none;
        }

        .popper-content-item {
          padding: 1.4rem 0 1.4rem 1.4rem;
          font-size: 1.4rem;
          color: $black;
          transition: all 0.3s;
          display: flex;
          justify-content: space-between;
          align-items: center;

          @include desktop-only {
            font-size: 1.2rem;
            border-radius: 0.8rem;
            padding: 1.4rem 2.4rem;
          }

          &:hover {
            font-weight: 800;

            @include desktop-only {
              background-color: $gray-6;
              font-weight: 400;
            }
          }
        }
      }
    }
  }

  .dropdown {
    width: 100vw;
    position: absolute;
    left: 0;
    top: 6.5rem;
    z-index: 1;
    display: none;

    &:after {
      content: '';
      background: rgba(#c1c1c1, 0.3);
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      width: 100vw;
      height: 100vh;
      z-index: -1;
      pointer-events: none;
    }

    .wrapper {
      background: $white;
      padding: 5.6rem 0 4rem 0;
      border-bottom: 1px solid $lighter-gray;
    }

    .container {
      flex-direction: column;
      align-items: flex-start;
      max-width: 1210px;
    }

    .categories {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      gap: 3.2rem;

      a {
        color: $black;
      }

      .image {
        margin-bottom: 0.6rem;
      }

      p {
        display: flex;
        align-items: center;

        svg {
          width: 12px;
          height: 12px;
          margin-left: 0.4rem;
        }
      }
    }
  }

  .dropdown-top {
    display: flex;
    align-items: flex-end;
    border-bottom: 1px solid $light-gray;
    padding-bottom: 3.2rem;
    margin-bottom: 1.6rem;
    width: 100%;

    a {
      color: $black;
      display: flex;
      align-items: center;
      margin-left: 2.4rem;

      svg {
        width: 12px;
        height: 12px;
        margin-left: 0.4rem;
      }
    }
  }

  .mobile-content {
    display: block;
    @media (min-width: 1200px) {
      display: none;
    }
  }

  .login {
    padding: 2rem 0 2.4rem 0;
    border-bottom: 1px solid $light-gray;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.8rem;

    button {
      margin: 0;
    }
  }

  .sign-out {
    padding: 2.4rem 0;
    border-bottom: 1px solid $light-gray;
    display: flex;
    justify-content: center;
  }

  .socials {
    text-align: center;
    padding: 2.6rem 0;

    p {
      font-size: 1.4rem;
    }

    .socials-icons {
      margin-top: 1.2rem;
    }
  }
}
</style>
