<template>
  <div class="payment-methods-icons">
    <div v-for="(item, index) in icons" :key="index" class="icon">
      <OptimizedImg alt="item" :size="{ height: 26, width: 40 }" :src="item" />
    </div>
  </div>
</template>

<script setup lang="ts">
import OptimizedImg from '@/Partials/ui/OptimizedImg.vue'

const icons = [
  'payment-methods/visa.svg',
  'payment-methods/mastercard.svg',
  'payment-methods/paypal.svg',
  'payment-methods/visa-electron.svg',
]
</script>

<style lang="scss">
.payment-methods-icons {
  display: grid;
  align-items: stretch;
  grid-column-gap: 10px;
  grid-template-columns: repeat(4, 1fr);

  .icon {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.4rem 0.3rem;
    border: 1px solid $light-gray;
    @include border-radius(xs);
  }

  img {
    max-height: 32px;
  }
}
</style>
