import axios from 'axios'
import useLangStore from '@/Store/useLangStore'

export const getRegistration = (data: { id: string; token: string }) => {
  return axios
    .get(`/${useLangStore().getLang()}/registrations/${data.id}/${data.token}`)
    .then((response) => response.data)
}

export const getPasswordReset = (data: { id: string; token: string }) => {
  const lang = useLangStore().getLang()

  return axios
    .get(`/${lang}/password-resets/${data.id}/${data.token}`)
    .then((response) => response.data)
}

export const postRegistration = (data: {}) => {
  const lang = useLangStore().getLang()
  return axios.post(`/${lang}/registrations`, data)
}

export const putRegistration = (data: {
  id: string
  token: string
  data: {}
}) => {
  const lang = useLangStore().getLang()
  return axios.put(`/${lang}/registrations/${data.id}/${data.token}`, data.data)
}

export const postSocialConfirmation = (data: { id: string; token: string }) => {
  const lang = useLangStore().getLang()
  return axios.post(`/${lang}/social-confirmations/${data.id}/${data.token}`)
}

export const postEmailChangeConfirmation = (data: {
  id: string
  token: string
}) => {
  const lang = useLangStore().getLang()
  return axios.post(
    `/${lang}/email-change-confirmations/${data.id}/${data.token}`,
  )
}

export const postPasswordReset = (data: {}) => {
  const lang = useLangStore().getLang()
  return axios.post(`/${lang}/password-resets`, data)
}

export const putPasswordReset = (data: {
  id: string
  token: string
  data: {}
}) => {
  const lang = useLangStore().getLang()
  return axios.put(
    `/${lang}/password-resets/${data.id}/${data.token}`,
    data.data,
  )
}
