import axios from 'axios'
import useLangStore from '@/Store/useLangStore'

export const getFaqs = (section_id: number | null = null) => {
  const params: { [key: string]: any } = {}

  if (section_id) {
    params['filter[site_tree_section_id]'] = section_id
  }
  return axios
    .get(`/${useLangStore().getLang()}/faqs`, {
      params: params,
    })
    .then((response) => response.data)
}
