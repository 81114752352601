import useLangStore from '@/Store/useLangStore'
import { Router } from 'vue-router'
import { useGlobalStore } from '@/Store/useGlobalStore'
import { useProductsStore } from '@/Store/useProductsStore'
import { SiteTreeSectionResource } from '@/Types/SiteTreeSection'
import { ProductResource } from '@/Types/Product'
import { redirectToError } from './Redirect'
import { PRO_PRODUCTS_ROUTE_NAME, PRO_PRODUCTS_SLUG } from './Constants'

const Html = () => import('@/Pages/Html.vue')
const Products = () => import('@/Pages/Products.vue')
const Faq = () => import('@/Pages/Faq.vue')
const ProductOpen = () => import('@/Pages/ProductOpen.vue')
const AboutUs = () => import('@/Pages/AboutUs.vue')
const Services = () => import('@/Pages/Services.vue')
const SampleSetsSection = () => import('@/Pages/SampleSetsSection.vue')
const DropshippingLandingPage = () =>
  import('@/Pages/DropshippingLanding/DropshippingLandingPage.vue')
const HowItWorksLandingPage = () =>
  import('@/Pages/HowItWorksLanding/HowItWorksLandingPage.vue')

const pageComponents = {
  html: Html,
  product: Products,
  faq: Faq,
  cookiebot: Html,
  'about-us': AboutUs,
  services: Services,
  'sample-sets-section': SampleSetsSection,
  'dropshipping-section': DropshippingLandingPage,
  'how-it-works-section': HowItWorksLandingPage,
} as const

const generateRoute = async (
  item: SiteTreeSectionResource,
  router: Router,
  parent: { meta: { type: string } } | null = null,
) => {
  const globalStore = useGlobalStore()
  const productsStore = useProductsStore()
  const lang = useLangStore().getLang()

  const routeData = {
    path: `/${lang}${item.slug}`,
    component: pageComponents[item.section_type as keyof typeof pageComponents],
    name: `${item.title}-${item.id}`,
    strict: true,
    title: item.title,
    meta: {
      title: item.title,
      type: item.section_type,
      parent_html:
        parent &&
        (parent.meta.type === 'html' ||
          parent.meta.type === 'faq' ||
          parent.meta.type === 'cookiebot')
          ? parent
          : null,
      meta_title: item.meta_title,
      meta_description: item.meta_description,
    },
  }

  if (item.section_type === 'product') {
    globalStore.setProductsUrl(routeData.path)
    await productsStore.setProductCategories()

    if (
      !router
        .getRoutes()
        .some((route) => route.name === PRO_PRODUCTS_ROUTE_NAME)
    ) {
      const proProductsBaseRoute = {
        path: `${routeData.path}/${PRO_PRODUCTS_SLUG}`,
        component: Products,
        name: PRO_PRODUCTS_ROUTE_NAME,
        strict: true,
        meta: { type: 'product' },
      }
      router.addRoute(proProductsBaseRoute)
    }

    const productCategories = productsStore.productCategories

    if (productCategories) {
      productCategories.forEach((category) => {
        const categoryRoutePath = {
          path: `${routeData.path}/${category.slug}`,
          component: Products,
          name: `product-category-${category.slug}`,
          title: item.title,
          strict: true,
          meta: {
            title: item.title,
            type: item.section_type,
          },
        }

        const proCategoryRoutePath = {
          ...categoryRoutePath,
          path: `${routeData.path}/${PRO_PRODUCTS_SLUG}/${category.slug}`,
          name: `pro-${categoryRoutePath.name}`,
        }

        router.addRoute(categoryRoutePath)
        router.addRoute(proCategoryRoutePath)

        const productOpenRouteData = {
          path: `${routeData.path}/${category.slug}/:slug`,
          component: ProductOpen,
          name: `product-open-${category.slug}`,
          strict: true,
          meta: {
            title: item.title,
            type: item.section_type,
          },
        }

        const proProductOpenRouteData = {
          ...productOpenRouteData,
          path: `${routeData.path}/${PRO_PRODUCTS_SLUG}/${category.slug}/:slug`,
          name: `pro-${productOpenRouteData.name}`,
        }

        router.addRoute(productOpenRouteData)
        router.addRoute(proProductOpenRouteData)
      })
    }
  }

  if (item.section_type === 'about-us') {
    globalStore.setAboutUsUrl(routeData.path)
  }

  if (item.section_type === 'services') {
    globalStore.setServicesUrl(routeData.path)
  }

  const faqUrl = globalStore.faqUrl
  if (!faqUrl && item.section_type === 'faq') {
    globalStore.setFaqUrl(routeData.path)
  }

  router.addRoute(routeData)

  if (!item.children) {
    return false
  } else {
    await Promise.all(
      item.children.map((item) => generateRoute(item, router, routeData)),
    )
  }
}

export const generateAllRoutes = async (router: Router) => {
  const globalStore = useGlobalStore()
  const langStore = useLangStore()

  let languages = globalStore.locales

  if (!languages) {
    await globalStore.setLocales()
    languages = globalStore.locales
  }

  if (!languages?.find((item) => item.code === langStore.getLang())) {
    redirectToError()
  }

  let menu = globalStore.siteTree

  if (!menu) {
    await globalStore.setSiteTree()
    menu = globalStore.siteTree

    //generate menu routes
    if (menu?.length) {
      await Promise.all(menu.map((menuItem) => generateRoute(menuItem, router)))
    }
  }
}

export const findBySlug = (
  menu: SiteTreeSectionResource[],
  slug: string,
): SiteTreeSectionResource | null => {
  for (let i = 0; i < menu.length; i++) {
    if (menu[i].slug === slug) return menu[i]
    if (menu[i].children && menu[i].children.length > 0) {
      const isChildren = findBySlug(menu[i].children, slug)
      if (isChildren) return isChildren
    }
  }

  return null
}

export const getProductCategorySlug = (
  product: Pick<ProductResource, 'category_id'>,
) => {
  const productsStore = useProductsStore()
  return productsStore.productCategories?.find(
    (item) => item.id === product.category_id,
  )?.slug
}

export const createLinkToProduct = (
  product: Pick<ProductResource, 'slug' | 'category_id'>,
) => {
  const globalStore = useGlobalStore()
  const categorySlug = getProductCategorySlug(product)
  return `${globalStore.productsUrl}/${categorySlug}/${product.slug}`
}
