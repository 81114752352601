<template>
  <div class="password-wrap">
    <input
      :id="input.name"
      passwordrules="minlength: 8; required: lower; required: upper; required: digit; required: [-];"
      placeholder=" "
      :type="passwordVisible ? 'text' : 'password'"
      @input="handleInput"
    />
    <label :for="input.name">{{ input.label }}</label>
    <a
      v-if="inputValue"
      class="eye"
      href="#"
      @click.prevent="passwordVisible = !passwordVisible"
    >
      <SvgIcon v-if="passwordVisible" name="eye-closed" />
      <SvgIcon v-else name="eye" />
    </a>
  </div>
</template>

<script setup lang="ts">
import SvgIcon from './SvgIcon.vue'
import { ref } from 'vue'

withDefaults(
  defineProps<{
    input?: { label: string; name: string }
  }>(),
  {
    input: () => ({
      label: 'Password',
      name: 'password',
    }),
  },
)

const emit = defineEmits<{ 'update:modelValue': [value: string] }>()

const passwordVisible = ref(false)

const inputValue = ref<string | null>(null)

const handleInput = (e: Event) => {
  inputValue.value = (e.target as HTMLInputElement)?.value
  emit('update:modelValue', inputValue.value)
}
</script>

<style lang="scss">
.password-wrap {
  .eye {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 1.6rem;
    z-index: 1;

    svg {
      width: 15px;
      height: 15px;
    }
  }
}
</style>
