import axios from 'axios'
import { useOrderStore } from '@/Store/useOrderStore'
import { useLoginStore } from '@/Store/useLoginStore'
import { getProductById } from './Products'
import { analyticsAddToCart } from '@/Mixins/Analytics'
import { fireErrorMessage } from '@/Mixins/Global'
import { CartResource } from '@/Types/Cart'
import { ShippingType } from '@/Types/ShippingType'
import { CartItemForm } from '@/Types/CartItem'
import useLangStore from '@/Store/useLangStore'
import { ProductVMType } from '@/ViewModels/ProductVM'

export const getCarts = async () => {
  const langStore = useLangStore()

  const expandArray = [
    'cart_items.design',
    'cart_items.product',
    'cart_items.product.gallery_photos',
    'cart_items.product.blacklisted_countries',
    'cart_items.product.sample_set_products',
    'cart_items.product.collection_box_products',
  ]
  const response = await axios.get(`/${langStore.getLang()}/carts`, {
    params: { expand: expandArray.join(',') },
  })

  return response.data as Array<
    CartResource<{ cart_items: 'product' | 'design' }>
  >
}

export const getShippingTypes = async (id: number) => {
  const langStore = useLangStore()

  return (
    await axios.get<ShippingType[]>(
      `/${langStore.getLang()}/carts/${id}/shipping-types`,
      { params: { sort: 'price' } },
    )
  ).data
}

export const postCart = async () => {
  const langStore = useLangStore()
  return await axios.post(`/${langStore.getLang()}/carts`)
}

export const putCart = async (
  data: { id: number; data: any },
  addIfPrivilegedAndStatusInBasket = false,
) => {
  const orderStore = useOrderStore()
  const loginStore = useLoginStore()
  const langStore = useLangStore()

  const response = await axios.patch(
    `/${langStore.getLang()}/carts/${data.id}`,
    data.data,
  )
  if (
    response.data.status === orderStore.statuses.PAYMENT_PENDING ||
    response.data.status === orderStore.statuses.FILE_SENT ||
    (addIfPrivilegedAndStatusInBasket && loginStore.profile?.is_privileged)
  ) {
    orderStore.setOrderState(response.data)
  }
}

export const postTestTapbox = async (data: { id: number }) => {
  const langStore = useLangStore()
  return await axios.post(
    `/${langStore.getLang()}/carts/${data.id}/test-tapbox`,
  )
}

export const postCartItem = async (
  data: Partial<CartItemForm> & { product_id: number },
  productProp?: ProductVMType | null,
) => {
  const langStore = useLangStore()

  try {
    await axios.post(`/${langStore.getLang()}/cart-items`, data, {
      headers: { 'Content-Type': 'multipart/form-data' },
    })

    const product =
      productProp ??
      (await getProductById(data.product_id, { expandArray: [] }))
    if (product) analyticsAddToCart(product, data.quantity)
  } catch (error) {
    if (
      error &&
      typeof error === 'object' &&
      'response' in error &&
      error.response &&
      typeof error.response === 'object' &&
      'data' in error.response &&
      error.response.data
    ) {
      fireErrorMessage('Something went wrong, please reload the page')
    }
  }
}

export const patchCartItem = async (data: {
  id: number
  data: Partial<CartItemForm>
}) => {
  const langStore = useLangStore()
  return await axios.patch(
    `/${langStore.getLang()}/cart-items/${data.id}`,
    data.data,
  )
}

export const deleteCartItem = async (id: number) => {
  const langStore = useLangStore()
  return await axios.delete(`/${langStore.getLang()}/cart-items/${id}`)
}
