import { SiteTreeSectionResource } from '@/Types/SiteTreeSection'

const generateNodesWithSlugs = (
  nodes: SiteTreeSectionResource[],
  slug = '',
) => {
  nodes.forEach((node) => {
    node.slug = `${slug}/${node.slug}`
    if (node.children) {
      generateNodesWithSlugs(
        node.children as SiteTreeSectionResource[],
        node.slug,
      )
    }
  })

  return nodes
}
export const listToTree = (
  list: SiteTreeSectionResource[],
  parentAttribute: keyof SiteTreeSectionResource,
  generateSlugs = true,
) => {
  const map: Record<string, number> = {}
  const roots: Array<SiteTreeSectionResource & Partial<{ url: string }>> = []

  list.forEach((item, index) => {
    map[item.id] = index
    item.children = []
  })

  list.forEach((node) => {
    if (node[parentAttribute]) {
      const parentNode = list[map[node[parentAttribute] as keyof typeof map]]
      if (parentNode) {
        parentNode.children = parentNode.children || []
        parentNode.children.push(node)
      }
    } else {
      roots.push(node)
    }
  })

  if (generateSlugs) return generateNodesWithSlugs(roots)

  return roots
}
