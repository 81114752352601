import axios from 'axios'
import {
  DesignConstructorData,
  DesignConstructorFormData,
  DesignForm,
  DesignResource,
  DesignResourceExpandableProperties,
  DesignResourceWithProduct,
} from '@/Types/Design'
import { fileUrlToFile, fireErrorMessage } from '@/Mixins/Global'
import useLangStore from '@/Store/useLangStore'
import ProductVM from '@/ViewModels/ProductVM'

const S = 'designs' as const

export const createDesign = async (
  formData: FormData,
): Promise<DesignResource | null> => {
  try {
    return (
      await axios.post(`/${useLangStore().getLang()}/${S}`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      })
    ).data
  } catch (e) {
    console.error(e)
    return null
  }
}

export const getDesigns = async (
  page: number,
  params: Record<string, unknown>,
  perPage = 20,
) => {
  const response = await axios.get<DesignResource[]>(
    `/${useLangStore().getLang()}/${S}`,
    {
      params: {
        filter: { is_active: true },
        page,
        'per-page': perPage,
        ...params,
      },
    },
  )

  return {
    data: response.data,
    page_count: parseInt(response.headers['x-pagination-page-count'] as string),
    page: parseInt(response.headers['x-pagination-current-page'] as string),
  }
}

export const getMyDesigns = async (
  page: number,
  params: Record<string, string> = {},
  perPage = 20,
) => {
  const expandArray = [
    'product',
    'product.blacklisted_countries',
    'product.gallery_photos',
    'product.inspiration_gallery_photos',
    'product.ingredient_layers',
    'product.ingredient_layers.countries',
    'product.print_design_templates',
    'product.print_design_templates.countries',
    'product.mockup_files',
    'product.layout_code_combo',
    'product.collection_box_products',
    'children',
    'storeProducts',
  ]

  const response = await axios.get<DesignResourceWithProduct[]>(
    `/${useLangStore().getLang()}/${S}`,
    {
      params: {
        filter: { is_active: true },
        expand: expandArray.join(','),
        page,
        'per-page': perPage,
        ...params,
      },
    },
  )
  return {
    data: response.data.map((d) => ({
      ...d,
      product: d.product ? ProductVM.createFrom(d.product) : d.product,
    })),
    page_count: parseInt(response.headers['x-pagination-page-count'] as string),
    page: parseInt(response.headers['x-pagination-current-page'] as string),
  }
}

export const hasAnyDesignDrafts = async () => {
  try {
    return (
      (await getMyDesigns(1, { 'filter[order_date][is]': 'NULL' })).data
        .length > 0
    )
  } catch (e: any) {
    console.error(e)
    return false
  }
}

export const duplicateDesign = async (
  design: Pick<
    DesignResource,
    | 'id'
    | 'product_id'
    | 'predefined_design_id'
    | 'predefined_design_text'
    | 'predefined_design_file_url'
    | 'mockup_image_url'
    | 'mockup_image_source_url'
    | 'children'
    | 'parent_id'
  > & { product_id: number },
) => {
  const designChildren: Array<DesignResource & { product_id: number }> =
    design.children || []

  const [designFormData, constructorData] = await Promise.all([
    getDuplicateDesignFormData(design),
    getDesignConstructorData(design.id),
  ])

  const newDesign = await createDesign(designFormData)

  if (newDesign && constructorData) {
    await updateDesignConstructorData(newDesign.id, constructorData)
  }

  try {
    if (newDesign && designChildren.length > 0) {
      await Promise.all(
        designChildren.map(async (child) => {
          if (!child.is_active) return

          const [childFormData, childConstructorData] = await Promise.all([
            getDuplicateDesignFormData({
              ...child,
              parent_id: newDesign.id,
            }),
            getDesignConstructorData(child.id),
          ])

          const newChildrenDesign = await createDesign(childFormData)

          if (newChildrenDesign && childConstructorData) {
            await updateDesignConstructorData(
              newChildrenDesign.id,
              childConstructorData,
            )
          }
        }),
      )
    }
  } catch (error) {
    const errorMessage = (error as any).response.data?.message
    fireErrorMessage(`Error duplicating design: ${errorMessage}`)
  }

  return newDesign
}

const getDuplicateDesignFormData = async (
  design: Pick<
    DesignResource,
    | 'id'
    | 'product_id'
    | 'predefined_design_id'
    | 'predefined_design_text'
    | 'predefined_design_file_url'
    | 'mockup_image_url'
    | 'mockup_image_source_url'
    | 'children'
    | 'parent_id'
  > & { product_id: number },
) => {
  const designFormData = new FormData()

  designFormData.append('product_id', design.product_id.toString())

  if (design.parent_id) {
    designFormData.append('parent_id', design.parent_id.toString())
  }

  if (design.predefined_design_id) {
    designFormData.append(
      'predefined_design_id',
      design.predefined_design_id.toString(),
    )
  }
  if (design.predefined_design_text) {
    designFormData.append(
      'predefined_design_text',
      design.predefined_design_text,
    )
  }
  if (design.predefined_design_file_url) {
    designFormData.append(
      'predefined_design_file',
      await fileUrlToFile(
        design.predefined_design_file_url,
        'predefined-design-file',
      ),
    )
  }
  if (design.mockup_image_source_url) {
    designFormData.append(
      'mockup_image',
      await fileUrlToFile(design.mockup_image_source_url, 'mockup-image'),
    )
  }

  return designFormData
}

export const getDesign = async <
  T extends DesignResourceExpandableProperties | never = never,
>(
  id: number,
  expandArray: readonly T[] = [],
  filters: Record<string, unknown> = {},
) => {
  try {
    return (
      await axios.get<DesignResource<T>>(
        `/${useLangStore().getLang()}/${S}/${id}`,
        {
          params: { expand: expandArray.join(','), ...filters },
        },
      )
    ).data
  } catch (e) {
    console.error(e)
    return null
  }
}

export const updateDesign = async (
  id: number,
  data: DesignForm,
): Promise<DesignResource | null> => {
  try {
    return (
      await axios.patch(`/${useLangStore().getLang()}/${S}/${id}`, data, {
        headers: { 'Content-Type': 'multipart/form-data' },
      })
    ).data
  } catch (e) {
    console.error(e)
    return null
  }
}

export const deleteDesign = async (id: number) => {
  return await axios.delete(`/${useLangStore().getLang()}/${S}/${id}`)
}

export const getDesignConstructorData = async (
  id: number,
): Promise<DesignConstructorData | null> => {
  try {
    return (await axios.get(`/${useLangStore().getLang()}/design-data/${id}`))
      .data
  } catch (e) {
    console.error(e)
    return null
  }
}

export const updateDesignConstructorData = async (
  id: number,
  data: DesignConstructorFormData | DesignConstructorData,
): Promise<DesignConstructorData | null> => {
  try {
    return (
      await axios.patch(`/${useLangStore().getLang()}/design-data/${id}`, data)
    ).data
  } catch (e) {
    console.error(e)
    return null
  }
}
