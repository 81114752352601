<template>
  <div class="sign-up-checkboxes">
    <div
      class="input-checkbox input-element large"
      :class="{ error: errors && findErrorField(errors, 'terms_accepted') }"
    >
      <div class="wrap">
        <input
          id="terms"
          v-model="termsData"
          type="checkbox"
          @change="updateTerms"
        />
        <label for="terms"
          >{{ __('checkout', 'By checking, you agree to') }}
          <a
            :href="pageLinks ? pageLinks.value.terms_url : ''"
            target="_blank"
            >{{ __('global', 'Terms & Conditions') }}</a
          >
          {{ __('global', 'and') }}
          <a
            :href="pageLinks ? pageLinks.value.privacy_policy_url : ''"
            target="_blank"
            >{{ __('global', 'Privacy policy') }}</a
          ></label
        >
      </div>

      <div
        v-if="errors && findErrorField(errors, 'terms_accepted')"
        class="errors"
      >
        <div v-for="i in errors" :key="i.field">
          <p v-if="i.field === 'terms_accepted'">{{ i.message }}</p>
        </div>
      </div>
    </div>

    <div
      v-if="newsletterShow"
      class="input-checkbox input-element large"
      :class="{ error: errors && findErrorField(errors, 'receive_emails') }"
    >
      <div class="wrap">
        <input
          id="newsletter"
          v-model="newsletterData"
          type="checkbox"
          @change="updateNewsletter"
        />
        <label for="newsletter">{{
          __(
            'checkout',
            'By creating and account, you agree to receive Newsletter, etc.',
          )
        }}</label>
      </div>

      <div
        v-if="errors && findErrorField(errors, 'receive_emails')"
        class="errors"
      >
        <div v-for="i in errors" :key="i.field">
          <p v-if="i.field === 'receive_emails'">{{ i.message }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, ref, watchEffect } from 'vue'
import { __ } from '@/Mixins/i18n'
import { findErrorField } from '@/Mixins/Global'
import { useGlobalStore } from '@/Store/useGlobalStore'
import { PageLinkSetting } from '@/Types/Setting'

const props = defineProps<{
  terms: boolean
  newsletter: boolean
  newsletterShow?: boolean
  errors?: { message: string; field: string }[]
}>()

const emit = defineEmits<{
  (e: 'updateTerms', value: boolean): void
  (e: 'updateNewsletter', value: boolean): void
}>()

const globalStore = useGlobalStore()

const termsData = ref(props.terms)
const newsletterData = ref(props.newsletter)

watchEffect(() => {
  termsData.value = props.terms
  newsletterData.value = props.newsletter
})

const pageLinks = computed(
  () =>
    globalStore.getSettingByKey('pagelinksetting') as
      | PageLinkSetting
      | undefined,
)

const updateTerms = (e: any) => {
  emit('updateTerms', e.target.checked)
}

const updateNewsletter = (e: any) => {
  emit('updateNewsletter', e.target.checked)
}
</script>

<style lang="scss" scoped>
input[type='checkbox'] {
  height: 2.3rem;
  width: 2.3rem;
  border-color: $light-gray;
}

label {
  color: $gray-7;
}
</style>
