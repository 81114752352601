import { getOrderFromLocal } from '@/Mixins/Checkout'
import { getCarts, postCart } from '@/Api/Cart'
import { defineStore } from 'pinia'
import { CartModified } from '@/Types/Cart'
import OrderItemVM from '@/ViewModels/OrderItemVM'
import { OrderModified } from '@/Types/Order'
import { OrderItemResource } from '@/Types/OrderItem'
import { useLoginStore } from './useLoginStore'

type State = { cart: CartModified | OrderModified | null }

export const useCartStore = defineStore('cart', {
  state: (): State => ({ cart: null }),
  actions: {
    async setCarts() {
      const carts = await getCarts()
      const order = await getOrderFromLocal()

      if (order) {
        this.cart = {
          ...order,
          cart_items: (order.order_items ?? []).map((x) =>
            OrderItemVM.createFromOrderItem(x as OrderItemResource<'product'>),
          ),
        }
      } else if (carts.length > 0) {
        this.cart = {
          ...carts[0],
          cart_items: carts[0].cart_items.map(OrderItemVM.createFromCartItem),
        }
      }
    },
    async initializeCartIfAuthenticated() {
      const loginStore = useLoginStore()
      if (!loginStore.userLoggedIn) return

      await this.setCarts()
      const carts = await getCarts()
      if (!carts.length) {
        await postCart()
        await this.setCarts()
      }
    },
    resetCart() {
      this.cart = null
    },
  },
  getters: {
    cartItemQuantity(state) {
      return (state.cart?.cart_items ?? []).reduce(
        (acc, item) => acc + item.quantity,
        0,
      )
    },
  },
})
