import Gtag, { event } from 'vue-gtag'
import { useCartStore } from '@/Store/useCartStore'
import { useLoginStore } from '@/Store/useLoginStore'
import { useGlobalStore } from '@/Store/useGlobalStore'
import { useOrderStore } from '@/Store/useOrderStore'
import OrderItemVM from '@/ViewModels/OrderItemVM'
import { setOrderAnalyticsData } from '@/Api/Order'
import { ProductVMType } from '@/ViewModels/ProductVM'

const getGtagClientIdFromCookie = () => {
  const clientIdCookie = document.cookie.match('(^|;)\\s*_ga\\s*=\\s*([^;]+)')
  return clientIdCookie?.pop()?.match('[0-9]*.[0-9]*$')?.pop() ?? ''
}

const getGtagSessionIdFromCookie = () => {
  const gaID = import.meta.env.VITE_GOOGLE_ANALYTICS_ID.replace('G-', '')
  const sessionIdCookie = document.cookie.match(
    `(^|;)\\s*_ga_${gaID}\\s*=\\s*([^;]+)`,
  )
  return sessionIdCookie?.pop()?.match('[0-9]{8,}')?.pop() ?? ''
}

const GTAG_REQUEST_TIMEOUT_MS = 1000

const getGtagClientAndSessionId = async () => {
  gtag =
    gtag ||
    (function gtag(...args: any[]) {
      if (window.dataLayer) window.dataLayer.push(args)
    } as Gtag)

  const getClientId = new Promise<string>((resolve) => {
    /** timeout in case an adblocker is blocking the gtag. the same approach is used for the session id
     * @see https://stackoverflow.com/questions/76216899/ad-blocker-blocks-gtag-get
     * */
    const timeout = setTimeout(
      () => resolve(getGtagClientIdFromCookie()),
      GTAG_REQUEST_TIMEOUT_MS,
    )

    gtag(
      'get',
      import.meta.env.VITE_GOOGLE_ANALYTICS_ID,
      'client_id',
      function (clientId) {
        clearTimeout(timeout)
        resolve((clientId ?? getGtagClientIdFromCookie()) as string)
      },
    )
  })

  const getSessionId = new Promise<string>((resolve) => {
    const timeout = setTimeout(
      () => resolve(getGtagSessionIdFromCookie()),
      GTAG_REQUEST_TIMEOUT_MS,
    )

    gtag(
      'get',
      import.meta.env.VITE_GOOGLE_ANALYTICS_ID,
      'session_id',
      function (sessionId) {
        clearTimeout(timeout)
        resolve((sessionId ?? getGtagSessionIdFromCookie()) as string)
      },
    )
  })

  const [clientId, sessionId] = await Promise.all([getClientId, getSessionId])

  return { clientId, sessionId }
}

export const analyticsBeginCheckout = async (
  items: Array<{
    id: string
    name: string
    quantity: number
    price: number | null
  }>,
) => {
  const cartStore = useCartStore()
  const loginStore = useLoginStore()

  const cart = cartStore.cart!
  const profile = loginStore.profile

  if (!profile || !profile.is_privileged) {
    const { clientId, sessionId } = await getGtagClientAndSessionId()
    await setOrderAnalyticsData(cart.id, clientId, sessionId)

    event('begin_checkout', {
      eventID: cart.id,
      items: items,
      session_id: sessionId,
      engagement_time_msec: '100',
    })
  }
}

export const analyticsAddToCart = (
  product: Pick<
    ProductVMType,
    'id' | 'title' | 'lowestAvailablePrice' | 'minimumQuantityToUse'
  >,
  quantity?: number,
) => {
  const cartStore = useCartStore()
  const loginStore = useLoginStore()

  const cart = cartStore.cart!
  const profile = loginStore.profile

  if (!profile || !profile.is_privileged) {
    event('add_to_cart', {
      eventID: `${cart.cart_items[cart.cart_items.length - 1].id}-${cart.id}`,
      items: [
        {
          id: product.id,
          name: product.title,
          quantity: quantity ?? product.minimumQuantityToUse,
          price: product.lowestAvailablePrice,
        },
      ],
    })
  }
}

export const analyticsRemoveFromCart = (cartItem: OrderItemVM) => {
  const loginStore = useLoginStore()
  const profile = loginStore.profile

  if (!profile || !profile.is_privileged) {
    event('remove_from_cart', {
      items: [
        {
          id: cartItem.productId,
          name: cartItem.product!.title,
          quantity: cartItem.quantity,
          price: cartItem.total,
        },
      ],
    })
  }
}

export const analyticsSelectContent = (
  product: Pick<ProductVMType, 'id' | 'title' | 'lowestAvailablePrice'>,
) => {
  const loginStore = useLoginStore()
  const profile = loginStore.profile

  if (!profile || !profile.is_privileged) {
    event('select_content', {
      content_type: 'product',
      items: [
        {
          id: product.id,
          name: product.title,
          price: product.lowestAvailablePrice,
        },
      ],
    })
  }
}

export const analyticsSetCheckoutOption = (step: number, title: string) => {
  const loginStore = useLoginStore()
  const profile = loginStore.profile

  if (!profile || !profile.is_privileged) {
    event('set_checkout_option', {
      checkout_step: step,
      checkout_option: title,
    })
  }
}

export const analyticsLogin = (method: string | null = null) => {
  let data = {}

  if (method) data = { method: method }
  event('login', data)
}

export const analyticsPurchase = (data: {
  value: number
  transaction_id: string
}) => {
  const loginStore = useLoginStore()
  const globalStore = useGlobalStore()

  const profile = loginStore.profile

  if (!profile || !profile.is_privileged) {
    const countries = globalStore.countries!
    const sessionSettings = globalStore.sessionSettings!

    const countryId = sessionSettings.find(
      (item) => item.id === 'CURRENT_COUNTRY',
    )?.value
    const currency = countries.find((item) => item.id === countryId)?.currency
      .name

    event('purchaseGTM', {
      transaction_id: data.transaction_id,
      value: data.value,
      currency: currency,
    })
  }
}

export const analyticsSignUp = (data: any) => {
  event('sign_up', {
    email: data.email,
    first_name: data.first_name,
    last_name: data.last_name,
    phone: data.phone,
  })
}

export const analyticsPaymentFailed = (data: {
  value: number
  transaction_id: string
}) => {
  const loginStore = useLoginStore()
  const profile = loginStore.profile

  if (!profile || !profile.is_privileged) {
    event('payment_failed', {
      transaction_id: data.transaction_id,
      value: data.value,
    })
  }
}

export const analyticsUploadFile = () => {
  const loginStore = useLoginStore()
  const profile = loginStore.profile

  if (!profile || !profile.is_privileged) event('upload_file')
}

export const analyticsProductFilter = (filters: string[]) => {
  const loginStore = useLoginStore()
  const profile = loginStore.profile

  if (!profile || !profile.is_privileged) event('product_filter', filters)
}

export const analyticsAddedPaymentInfo = () => {
  const loginStore = useLoginStore()
  const orderStore = useOrderStore()
  const globalStore = useGlobalStore()

  const profile = loginStore.profile

  if (!profile || !profile.is_privileged) {
    const order = orderStore.order!
    const countries = globalStore.countries
    const sessionSettings = globalStore.sessionSettings

    const countryId = sessionSettings!.find(
      (item) => item.id === 'CURRENT_COUNTRY',
    )?.value
    const currency = countries!.find((item) => item.id === countryId)?.currency
      .name

    event('add_payment_info', { eventID: order.nr, currency: currency })
  }
}

export const analyticsCompleteRegistration = (id: string) => {
  event('complete_registration', { eventID: id })
}
