<template>
  <a
    v-if="newUrl.startsWith('http')"
    :href="newUrl"
    :target="targetBlank ? '_blank' : '_self'"
  >
    <slot></slot>
  </a>
  <router-link v-else :to="newUrl"><slot></slot></router-link>
</template>

<script setup lang="ts">
import { computed } from 'vue'

const props = defineProps<{ url: string; targetBlank?: boolean }>()

const newUrl = computed(() =>
  props.url.includes(location.origin)
    ? props.url.replace(location.origin, '')
    : props.url,
)
</script>
