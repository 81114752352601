import axios from 'axios'
import {
  CountryResource,
  CountryResourceExpandableProperties,
} from '@/Types/Country'
import { SiteTreeSectionResource } from '@/Types/SiteTreeSection'
import useLangStore from '@/Store/useLangStore'

export const getLocales = () => {
  return axios.get(`/locales`).then((response) => response.data)
}

export const getSiteTree = (): Promise<SiteTreeSectionResource[]> => {
  return axios
    .get(`/${useLangStore().getLang()}/site-tree-sections`, {
      params: {
        'per-page': '-1',
      },
    })
    .then((response) => response.data)
}

export const getHeaderMenu = () => {
  return axios
    .get(`/${useLangStore().getLang()}/header-menu-items`)
    .then((response) => response.data)
}

export const getFooterLinks = (): Promise<SiteTreeSectionResource[]> => {
  return axios
    .get(`/${useLangStore().getLang()}/footer-links?per-page=-1`)
    .then((response) => response.data)
}

export const getTranslations = () => {
  return axios
    .get(`/${useLangStore().getLang()}/static-translations`)
    .then((response) => response.data)
}

export const getSessionSettings = async () => {
  return (await axios.get(`/${useLangStore().getLang()}/session-settings`)).data
}

export const getCountries = async <
  T extends CountryResourceExpandableProperties,
>(
  expandArray: readonly T[] = [],
) => {
  const response = await axios.get<CountryResource<T>[]>(
    `/${useLangStore().getLang()}/countries`,
    {
      params: {
        'per-page': '-1',
        expand: expandArray.join(','),
      },
    },
  )
  return response.data
}

export const getSettings = () => {
  return axios
    .get(`/${useLangStore().getLang()}/settings`)
    .then((response) => response.data)
}
