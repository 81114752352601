import axios from 'axios'
import { OrderForm, OrderResource, OrderStatus, Status } from '@/Types/Order'
import { OrderAnalyticsDataResource } from '@/Types/OrderAnalyticsData'
import { OrderItemResource } from '@/Types/OrderItem'
import useLangStore from '@/Store/useLangStore'

export type GetOrdersData = {
  page?: number
  per_page?: number
  sort?: string
  statuses?: string[]
  storeIds?: Array<number | string>
  expand?: string
}

export const getOrders = async (data: GetOrdersData) => {
  const langStore = useLangStore()

  const params: {
    [key: string]:
      | string
      | number
      | undefined
      | string[]
      | Array<number | string>
  } = {
    page: data.page ?? 1,
    'per-page': data.per_page ?? 10,
  }

  if (data && 'sort' in data) {
    params['sort'] = data.sort
  } else {
    params['sort'] = '-created_at'
  }

  if (data.statuses && data.statuses.length) {
    params['filter[status_decoded][in][]'] = data.statuses
  }
  if (data.storeIds?.length) {
    params['filter[store_id][in][]'] = data.storeIds
  }
  if (data.expand) params['expand'] = data.expand

  try {
    const response = await axios.get(`/${langStore.getLang()}/orders`, {
      params,
    })
    return {
      data: response.data,
      page: parseInt(response.headers['x-pagination-current-page'] as string),
      page_count: parseInt(
        response.headers['x-pagination-page-count'] as string,
      ),
    }
  } catch (e) {
    console.error(e)
  }
}

export const getUnreadOrdersCount = async () => {
  const langStore = useLangStore()

  try {
    const { headers } = await axios.get(`/${langStore.getLang()}/orders`, {
      params: { 'per-page': 1, filter: { is_unread: true } },
    })
    return parseInt(headers['x-pagination-total-count'] as string)
  } catch (e) {
    console.error(e)
    return 0
  }
}

export const getOrder = async <
  T extends
    | 'drop_shipping_data'
    | 'delivery_order_address'
    | 'billing_order_address',
>(
  id: string,
  expandArrayProp: readonly T[] = [],
) => {
  const langStore = useLangStore()

  const expandArray = [
    'customer',
    'order_items',
    'order_items.product',
    'order_items.design',
    'order_items.product.gallery_photos',
    'order_items.product.blacklisted_countries',
    'order_items.product.sample_set_products',
    'order_items.product.collection_box_products',
    ...expandArrayProp,
  ] as const

  const response = await axios.get(`/${langStore.getLang()}/orders/${id}`, {
    params: { expand: expandArray.join(',') },
  })

  return response.data as OrderResource<
    'customer' | 'order_items' | T | { order_items: 'product' }
  >
}

export const getOrderByNr = (nr: string): Promise<OrderResource[]> => {
  const expandArray = [
    'customer',
    'store',
    'order_items.product',
    'order_items.design',
  ] as const

  return axios
    .get(`/${useLangStore().getLang()}/orders?filter[nr]=${nr}`, {
      params: { expand: expandArray.join(',') },
    })
    .then((response) => response.data)
    .catch((e) => {
      console.error(e)
      return []
    })
}

export const updateOrder = async (
  reference: string,
  data: Partial<OrderForm>,
) => {
  const langStore = useLangStore()

  return (
    await axios.patch<typeof data, { data: OrderResource }>(
      `/${langStore.getLang()}/orders/${reference}`,
      data,
    )
  ).data
}

export const getOrderItemsOfOrder = async (orderId: number, sort?: string) => {
  const expandArray = [
    'design',
    'product',
    'product.gallery_photos',
    'product.blacklisted_countries',
    'product.sample_set_products',
  ] as unknown as ['product', 'design']

  const params: Record<string, string | number> = {
    expand: expandArray.join(','),
    'filter[order_id]': orderId,
  }

  if (sort) params['sort'] = sort

  return (
    await axios.get<OrderItemResource<(typeof expandArray)[number]>[]>(
      `/${useLangStore().getLang()}/order-items`,
      { params },
    )
  ).data
}

export const getSampleOrderItems = () => {
  const lang = useLangStore().getLang()

  return axios
    .get<OrderItemResource[]>(`/${lang}/order-items`, {
      params: { filter: { is_sample: 1 } },
    })
    .then((response) => response.data)
    .catch((e) => {
      console.error(e)
      return []
    })
}

export const getStatuses = async () => {
  const langStore = useLangStore()
  return (await axios.get<Status[]>(`/${langStore.getLang()}/order-statuses`))
    .data
}

export const setOrderAnalyticsData = async (
  cartId: number,
  googleClientId: string,
  googleSessionId: string,
) => {
  const lang = useLangStore().getLang()

  const response = await axios.post<OrderAnalyticsDataResource>(
    `/${lang}/order-analytics-data`,
    {
      cart_id: cartId,
      google_client_id: googleClientId,
      google_session_id: googleSessionId,
    },
  )

  return response.data
}

export const retryOrderPayment = async (orderReference: string) => {
  return await updateOrder(orderReference, {
    status: OrderStatus.PAYMENT_PENDING,
  })
}
