<template>
  <section v-if="seoBlock" class="seo-footer-section">
    <h2>
      {{ seoBlock.title }}
    </h2>
    <div class="body-wrapper">
      <div
        ref="body"
        class="body"
        :class="{ clamp: !readMore }"
        v-html="seoBlock.description"
      ></div>
      <button v-if="!readMore && isClamped" @click="readMore = true">
        Read more
      </button>
    </div>
    <div class="action-row">
      <ActionElement
        v-if="seoBlock.left_button_text && seoBlock.left_button_action_type"
        :action-type="seoBlock.left_button_action_type"
        class="btn btn-large btn-primary"
        :text="seoBlock.left_button_text"
        :url="seoBlock.left_button_link"
      />
      <ActionElement
        v-if="seoBlock.right_button_text && seoBlock.right_button_action_type"
        :action-type="seoBlock.right_button_action_type"
        class="btn btn-large btn-secondary"
        :text="seoBlock.right_button_text"
        :url="seoBlock.right_button_link"
      />
    </div>
  </section>
</template>

<script setup lang="ts">
import ActionElement from '@/Pages/DropshippingLanding/Components/ActionElement.vue'
import { computed, ref, watch } from 'vue'
import { SiteTreeSectionResource } from '@/Types/SiteTreeSection'
import { RouteLocationNormalized, RouteMeta, useRoute } from 'vue-router'
import { useGlobalStore } from '@/Store/useGlobalStore'
import { findBySlug } from '@/Mixins/SiteTreeHelper'
import { getSeoBlock } from '@/Api/SeoBlock'
import { SeoBlockResource } from '@/Types/SeoBlock'
import useFooterStore from '@/Store/useFooterStore'

type RouteMetaExtended = RouteMeta & {
  parent_html: RouteLocationNormalized
}

const route = useRoute()
const globalStore = useGlobalStore()
const footerStore = useFooterStore()

const body = ref<HTMLElement | null>(null)
const readMore = ref(false)
const isClamped = ref(false)
const seoBlock = ref<SeoBlockResource | null>(null)

const path = computed(() => {
  return (route.meta as RouteMetaExtended).parent_html
    ? (route.meta as RouteMetaExtended).parent_html.path
    : route.path
})

const menu = computed(() => globalStore.siteTree as SiteTreeSectionResource[])
const seoBlockId = computed(() => {
  return (
    findBySlug(menu.value, `/${path.value.substring(4)}`)?.seo_block_id ?? null
  )
})

watch(
  body,
  (value) => {
    if (value) {
      isClamped.value = value.scrollHeight > value.clientHeight
    }
  },
  { immediate: true },
)

watch(
  seoBlockId,
  (seoBlockId) => {
    if (seoBlockId) {
      getSeoBlockResource(seoBlockId)
    } else {
      resetSeoBlock()
    }
  },
  { immediate: true },
)

async function getSeoBlockResource(seoBlockId: number) {
  seoBlock.value = (await getSeoBlock(seoBlockId)) as SeoBlockResource

  footerStore.toggleShowSeoSection(seoBlock.value !== null)
}

function resetSeoBlock() {
  seoBlock.value = null
  footerStore.toggleShowSeoSection(false)
  readMore.value = false
}
</script>

<style scoped lang="scss">
.seo-footer-section {
  padding: 7.2rem 3.2rem 8rem 3.2rem;
  max-width: 67.6rem;
  margin: 0 auto;
  @include desktop-only {
    padding: 14rem 0 14rem 0;
  }
}
h2 {
  font-weight: 500;
  margin-bottom: 2rem;
  text-align: center;
  @include desktop-only {
    margin-bottom: 4rem;
  }
}

.body-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;

  button {
    background: none;
    border: none;
    text-decoration: underline;
    font-size: 1.4rem;
    line-height: 160%;
    letter-spacing: -0.014rem;
    white-space: nowrap;
    cursor: pointer;
    color: $black;
  }

  .body {
    &.clamp {
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
      @include desktop-only {
        -webkit-line-clamp: 2;
      }
    }
  }

  :deep(.body) {
    text-align: center;
    white-space: preserve-spaces;
    display: flex;
    flex-direction: column;
    gap: 1.8rem;

    ul,
    ol {
      list-style-position: inside;
      margin: 0;
      padding: 0;
    }

    ul {
      list-style-type: disc;
    }
  }
}

.action-row {
  display: flex;
  align-items: center;
  gap: 0.4rem;
  justify-content: center;
  margin-top: 3.2rem;
  @include desktop-only {
    margin-top: 5.6rem;
  }
}
</style>
